@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* fraunces-500 - latin */
@font-face {
  font-family: "Fraunces";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/fraunces-v22-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/fraunces-v22-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/fraunces-v22-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/fraunces-v22-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/fraunces-v22-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/fraunces-v22-latin-500.svg#Fraunces") format("svg"); /* Legacy iOS */
}
